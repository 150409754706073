/*
Documentation

this page handles log in for a user
on successful login we set a cookie architeck_uid
when we send off to see if the user is logged in this is 
passed along as well to prevent cors cookie issues

*/

import classnames from "classnames";
import { architeckCall } from 'database';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import { setViewingUser } from 'store/functions/auth';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Login extends React.Component {

	state = {
        // email: 'john@architeck.io',
        // password: 'Aaaa123*'
        email: window.location.hostname === 'localhost' ? 'john@architeck.io' : '',
        password: window.location.hostname === 'localhost' ? 'Aaaa123*' : '',
    };
 
	//on keypress of enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {

			document.getElementById("loginButton").click();

		}
	}

	  onLogin = async () => {

        this.setState({error: null})

		const email = this.state.email;
		const password = this.state.password;
		
		if(!this.state.email) {
			this.setState({error: 'Invalid Email'});
			return;
		}

		if(!this.state.password) {
			this.setState({error: 'Invalid Password'});
			return;
		}

        //validate the users password
        const user = await architeckCall({
            method:'post',
            url: '/api/v1/auth/password_validate',
            withCredentials: true, 
            data: {
                password: password,
                email: email
            }
        })

        if(user.success) {

            const user_id = user._id;

            //validate the users password
            const login = await architeckCall({
                method:'post',
                url: '/api/v1/auth/login',
                data: {
                    user_id
                }
			})
			
            if(login.success) {

				//set a cookie on the user
				cookies.set('architeck_uid', login.user._id, { path: '/',  });
				await setViewingUser();
				
				this.setState({shouldRedirect: '/dashboard'})

            } else {

                this.setState({error: login.message});

            }
            
        } else {

            this.setState({error: user.message});
            
        }

	}
    
	render() {

		if(this.state.shouldRedirect) {
			return <Redirect to={this.state.shouldRedirect} />
		}

		return (

		<div className="login">

			<Helmet>
				<title>Login</title>
				<meta name="description" content="Login" />
			</Helmet>



            <div className="header bg-secondary py-7 py-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">
                                <h1>Welcome</h1>
                                <p className="text-lead ">To the future of software development.</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="separator separator-bottom separator-skew zindex-100">
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0" >
                        <polygon className="fill-default" points="2560 0 2560 100 0 100"/>
                    </svg>
                </div>
            </div>

			<Container className="mt--8 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">
						<Card className="bg-white border-0 mb-0">

							<CardBody className="px-lg-5 py-lg-5">

								<div className="text-center text-muted mb-4">
									<small>Sign in via our secured login portal.</small>
								</div>

								<Form role="form">
									<FormGroup className={classnames("mb-3", { focused: this.state.focusedEmail })} >

										<InputGroup className="input-group-merge input-group-alternative">

											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ni ni-email-83" />
												</InputGroupText>
											</InputGroupAddon>

											<Input
												name="email"
												onKeyDown={this._handleKeyDown}
												placeholder="Email"
												type="email"
												value={this.state.email	 || ''}
												onChange={(e) => this.setState({email: e.target.value})}
												onFocus={() => this.setState({ focusedEmail: true })}
												onBlur={() => this.setState({ focusedEmail: false })}
											/>
											
										</InputGroup>
										
									</FormGroup>

									<FormGroup className={classnames({focused: this.state.focusedPassword})}>

										<InputGroup className="input-group-merge input-group-alternative">

											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ni ni-lock-circle-open" />
												</InputGroupText>
											</InputGroupAddon>

											<Input
												name="password"
												onKeyDown={this._handleKeyDown}
												placeholder="Password"
												type="password"
												value={this.state.password || ''}
												onChange={(e) => this.setState({password: e.target.value})}
												onFocus={() => this.setState({ focusedPassword: true }) }
												onBlur={() => this.setState({ focusedPassword: false })}
											/>

										</InputGroup>

									</FormGroup>

									<div className="text-center">
										{this.state.error && <span className="text-danger small">{this.state.error}<br /></span>}
										<Button id="loginButton" onClick={this.onLogin} className="my-4" color="info" type="button">
											Sign in
										</Button>
									</div>
									
								</Form>
							</CardBody>
						</Card>

						<div className="text-center mt-3">
							
							<Link className="text-light" to="/auth/forgot" >
								<small>Forgot Password</small>
							</Link>

						</div>

					</Col>
				</Row>
			</Container> 
		</div>
		);
	}
}

export default Login

