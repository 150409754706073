/*
Documentation

This component takes an image url and returns it if it exists
if it does not it will return a default image

*/

import React from "react";
import DefaultAvatar from './DefaultAvatar.jpg'

class AvatarImage extends React.Component {
  
    state = {
        error: false
    }

    onError = () =>  this.setState({ error: true });

    render() {

        const alt = this.props.alt ? this.props.alt : '...'

        if(!this.props.src || this.state.error) {

            return <img className={this.props.className}  src={DefaultAvatar} alt={alt} /> 

        } else {

            return <img className={this.props.className} src={this.props.src} onError={this.onError} alt={alt} /> 

        }

        
    }

}
export default AvatarImage;