/*
Documentation

this page holds all the routes for the auth layout

*/

import Forgot from '../pages/Forgot';
import Login from '../pages/Login';
import Logout from '../pages/Logout';

export default [

    {
        path: "/forgot",
        component: Forgot,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/logout",
        component: Logout,
    },
    {
        path: "",
        component: Login,
    },

]