import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, Col, Row, Progress } from "reactstrap";

import Axios from 'axios';



const { ExportCSVButton } = CSVExport;

const oneDay = 86400;


const pagination = paginationFactory({
    sizePerPage: 7,
    page: 1,
    alwaysShowAllBtns: true,
    // hideSizePerPage: true,
    showTotal: true,
    withFirstAndLast: true,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {<select
                    name="datatable-basic_length"
                    aria-controls="datatable-basic"
                    className="form-control form-control-sm"
                    onChange={e => onSizePerPageChange(e.target.value)}
                >
                    <option value="7">7</option>
                    <option value="14">14</option>
                    <option value="30">50</option>
                    <option value="365">365</option>
                </select>}{" "}
                entries.
            </label>
        </div>
    )
});
 
class SinglePage extends React.Component {

    state = {
        load_time: 0,
        weekly_data: [],
        data: this.props.data,
        last_page_view: {},

        yearly_view_all: 0,
        yearly_view_unique: 0,
        most_viewed_date: 0,

    }

    columns = [
        {
            dataField: "date",
            text: "Date",
            sort: true,
            formatter: (cell, row) => {
                return moment.utc(row.date, 'X').format("MMM D YYYY ")
            }
        },{
            dataField: "views_unique",
            text: "Unique View",
            sort: true
        }, {
            dataField: "views_all",
            text: "Total Views",
            sort: true
        }, {
            dataField: "load_time",
            text: "Avg Page Load Time",
            sort: true,
            formatter: (cell, row) => {
                return row.load_time ? <span>{(row.load_time / 1000).toFixed(3) } Seconds</span> : '-';
            }
        }, 
    ]
  
    calculateYearlyTotal = () => {

        let yearly_view_unique = 0;
        let yearly_view_all = 0;

        let most_viewed_date = 0

        this.props.data.forEach(d => {
            yearly_view_all = yearly_view_all + d.views_all;
            yearly_view_unique = yearly_view_unique + d.views_unique;

            if(d.views_all > most_viewed_date) {
                most_viewed_date = d.date
            }
        })

        this.setState({yearly_view_all, yearly_view_unique, most_viewed_date})


    }

    setLastPageView = () => {

        const data = [...this.props.data];
        const last_page_view = data.reverse().slice(data.length -1, data.length)[0]

        this.setState({last_page_view})
    }

    setAverageLoadTime = () => {

        let load_time
        let times_incremented = 0
        let loading_percent;
        let loading_color
        let should_loop = true;
        let loop_int = 1;

        this.props.data.forEach(d => {

            if(d.load_time) {

                console.log(d.load_time)

                if(!load_time) {
                    load_time = d.load_time
                } else {

                    load_time = load_time + d.load_time

                }

                times_incremented++;

            }

        })

        load_time = ((load_time) / times_incremented).toFixed(2) ;
  

        while (should_loop) {
            
            if(parseInt(load_time / 1000) < loop_int) {

                loading_percent = 100 - (loop_int * 5)

                if(loading_percent > 80) {
                    loading_color = 'success'
                } else if (loading_percent > 60) {
                    loading_color = 'warning'
                } else {
                    loading_color = 'danger'
                }

                should_loop = false
            }

            loop_int = loop_int + .2


            if(loop_int > 100) {
                console.log('break loop')
                should_loop = false
            }


        }

        this.setState({load_time, loading_percent, loading_color})

    }

    componentDidMount = async () => {

       

        this.setLastPageView()
        this.calculateYearlyTotal()
        this.setAverageLoadTime()

    }

    getWeeklyMarkup = () => {

        let weekly_data = [...this.props.data];
        weekly_data = weekly_data.reverse().slice(this.props.data.length - 7, this.props.data.length ).reverse();

        let hasWeekData = weekly_data.length >= 6 ? true : false


        const header = weekly_data.map(d => (
            <th  key={d._id}>
                {moment.utc(d.date, 'X').format("MM/DD ")}
            </th>
        ))

        let body = weekly_data.map(d => (
            <td key={d._id}>
                {d.views_unique} / {d.views_all}
            </td>
        ))

        if(!hasWeekData) {
            body.push((
                <td>
                    Data Not Available
                </td>
            ))
        }


        return (
            <Card>
                <CardHeader>
                    <h3 className="mb-1">
                        Weekly Breakdown {' | '}
                        {moment.utc(weekly_data[hasWeekData ? 6 : weekly_data.length - 1].date, 'X').format("MMM Do ")} &mdash; {' '}
                        {moment.utc(weekly_data[0].date, 'X').format("MMM Do ")} 
                        {moment.utc(weekly_data[0].date, 'X').format("YYYY ")} 
                    </h3>
                    <p className="text-sm mb-0">** unique views / total views for that day</p>
                </CardHeader>

                <table className="table">
                    <thead>
                        <tr>
                            {header}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {body}
                        </tr>
                    </tbody>
                </table>

            </Card>
           
        )

    }
    
    render() {

        const data = this.state.data ? this.state.data : null;

        const last_page_view = this.state.last_page_view
        const most_viewed_date = this.state.most_viewed_date
        const load_time = (this.state.load_time / 1000 ).toFixed(2)

        if(!data.length ) {
            return (
                <h2>No Data</h2>
            )
        }

        return (
            <>

           <Row>
               <Col lg={6}>
                    <Card>
                        <CardHeader>
                            <h3 className="mb-0">Average Load Speed</h3>
                        </CardHeader>
                        <CardBody>
                            <div className="progress-wrapper pt-0">
                                <div className="progress-success">
                                
                                    <div className="progress-percentage">
                                        
                                        <span>
                                        {load_time} seconds
                                        </span>
                                    </div>
                                </div>

                                <Progress max="100" value={this.state.loading_percent} color={this.state.loading_color} />

                            </div>
                        </CardBody>
                    </Card>
               </Col>

               <Col lg={6}>
                    <Card>
                        <CardHeader>
                            <h3 className="mb-0">Views This Year</h3>
                        </CardHeader>
                        <CardBody>
                            <div className="progress-wrapper pt-0">
                                <div className="progress-success">
                                
                                    <div className="progress-percentage">
                                        
                                        <span>
                                        Unique {this.state.yearly_view_unique} - All {this.state.yearly_view_all}
                                        </span>
                                    </div>
                                </div>

                                <Progress max="100" value={(this.state.yearly_view_unique / this.state.yearly_view_all) * 100} color="info" />

                            </div>
                        </CardBody>
                    </Card>
               </Col>
           </Row>

            {this.getWeeklyMarkup()}

            <Card>

                <CardHeader>
                    <h3 className="mb-0">Information: {this.state.last_page_view.page === '/' ? 'Home Page' : this.state.last_page_view.page} </h3>
                </CardHeader>

                <Row>
                    <Col lg={6}>
                        <table className="table">
                           
                            <tbody>
                                <tr>
                                    <td style={{borderTop: 'none'}}><b>Last Page View:</b></td>
                                    <td style={{borderTop: 'none'}}>{moment.utc(last_page_view.date, 'X').format("MMM Do YYYY")}</td>
                                </tr>
                                <tr>
                                    <td style={{borderTop: 'none'}}><b>Unique Views This Year:</b></td>
                                    <td style={{borderTop: 'none'}}>{this.state.yearly_view_unique}</td>
                                </tr>
                                <tr>
                                    <td style={{borderTop: 'none'}}><b>All Views This Year:</b></td>
                                    <td style={{borderTop: 'none'}}>{this.state.yearly_view_all}</td>
                                </tr>
                                <tr>
                                    <td style={{borderTop: 'none'}}><b>This Page Was Most Viewed On:</b></td>
                                    <td style={{borderTop: 'none'}}>{moment.utc(most_viewed_date, 'X').format("MMM Do YYYY")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col lg={6} className="text-center">
                       
                    </Col>
                </Row>

            </Card>
                
            <Card>

                <CardHeader>
                    <h3 className="mb-0">Views This Year For: {this.state.last_page_view.page === '/' ? 'Home' : this.state.last_page_view.page} </h3>
                </CardHeader>

                <div className="table-vertical-align">
                    <ToolkitProvider
                        data={data}
                        keyField="_id"
                        columns={this.columns}
                        search
                        exportCSV
                    >
                    {props => (
                        <div className="py-4 table-responsive table-vertical-align">
                            <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1 w-100" >
                                <Row>

                                    <Col className="align-self-center">
                                        <p className="text-sm" style={{whiteSpace: 'pre-wrap'}}>Recent days that do not show up mean no user has visited this page since the last entry.</p>
                                    </Col>

                                    <Col className="col-auto align-self-center">
                                        <ExportCSVButton { ...props.csvProps } className="btn-sm btn-success mb-3" >
                                            <span>Export CSV</span>
                                        </ExportCSVButton>
                                    </Col>

                                </Row>
                            </div>
                            <BootstrapTable 
                                {...props.baseProps} 
                                className="xxx" 
                                bootstrap4={true} 
                                pagination={pagination} 
                                bordered={false}
                            />
                        </div>
                    )}
                    </ToolkitProvider>
                </div>

            </Card>



            </>
        );
    }
}


export default SinglePage

