/*
Documentation

this page holds all the routes for the dashboard layout

*/

// import Dashboard from '../pages/Dashboard';
import Settings from '../pages/Settings';

import AnalyticsAll from '../pages/analytics/All'
import AnalyticsView from '../pages/analytics/View'

export default [

    {
        divider: "Analytics",
        name: 'All Sites',
        icon: 'fas fa-home text-primary',
        path: "/",
        component: AnalyticsAll,
        show_in_sidebar: true
    },
   
    {
        path: "/analytics/:_id",
        component: AnalyticsView,
        show_in_sidebar: false
    },

    

    {
        divider: "Profile",
        name: 'Settings',
        icon: 'fas fa-cogs text-success',
        path: "/settings",
        component: Settings,
        show_in_sidebar: true
    },

]