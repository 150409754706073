/*
Documentation

this page logs out a user and clears their
client side architeck_uid cookie

*/

import { architeckCall } from 'database';
import React from "react";
import { Redirect } from "react-router-dom";
import { setViewingUser } from 'store/functions/auth'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const logout_bg_color = 'white'

class Logout extends React.Component {

    state = {}
	
    componentDidMount = async () => {

        const logout = await architeckCall({
            method:'post',
            url: '/api/v1/auth/logout',
        })

        if(logout.success) {

            //clear client side login cookie
            cookies.set('architeck_uid', null, { path: '/' });
            await setViewingUser()
            this.setState({shouldRedirect: '/auth/login'})

        } else {

            this.setState({error: logout.message});

        }

    }

	render() {

		if(this.state.shouldRedirect) {
			return <Redirect to={this.state.shouldRedirect} />
        }
        
		return (

         <div className="text-center d-flex position-fixed top-0 bottom-0 right-0 left-0" style={styles.bg}>

            {this.state.error && (
                <div className="align-self-center w-100">
                    <h2>{this.state.error}</h2>    
                </div>
            )}

         </div>

           
		);
	}
}

const styles = {

    bg: {
        background: logout_bg_color, 
        zIndex: 99999999999,
    },

}
  
export default Logout

