/*
Documentation

this page holds all of our layouts and functions
that need to fire before the app runs

*/

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { setViewingUser } from 'store/functions/auth';

import Site from 'views/site/layout';
import Auth from 'views/auth/layout';
import Dashboard from 'views/dashboard/layout';

import SystemAlert from './components/markup/system/Alert'

class App extends Component {

    state = {
        loaded: false,
        error: false
    };

    componentDidMount = async () => {

        
        //run all promises that need to fire before the
        //app loads here
        Promise.all([

            setViewingUser(),

        ]).then((values) => {
            
            this.setState({loaded: true})

        })

    }

    render() {

        if(!this.state.loaded) {
            return <div></div>
        }

        if(this.state.error) {
            return <div className="text-center pt-6"><h2>AN ERROR HAS OCCURRED WITH OUR SERVERS.</h2></div>
        }

        return (

            <>

            <SystemAlert />

            <BrowserRouter>
                <Switch>
               

                    <Route path="/dashboard" render={props => <Dashboard {...props} /> } />
                    <Route path="/auth" render={props => <Auth {...props} /> } />
                    <Route path="/" render={props => <Site {...props} /> } />

                </Switch>
            </BrowserRouter>

            </>

        )

    }

}

export default App;