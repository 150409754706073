/*
Documentation

this is the wrapping component for all auth routes
if a user is already known and logged in it will redirect 
them off to their destination

if not destination is know we will redirect to /dashboard

*/

import keys from 'keys';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { Container, Navbar, NavbarBrand } from "reactstrap";
import { getUrlParameter } from 'utils/urls';
import routes from "../layout/routes";

const base_layout = 'auth';

class Auth extends Component {

    state = {

    }

    componentDidMount() {

        //if we are not on the logout page
        if(window.location.pathname !== '/auth/logout') {
            const redirect = getUrlParameter('redirect')

            const viewing_user = this.props.viewing_user;

            if(viewing_user && viewing_user.logged_in) {

                if(redirect) {

                    this.setState({shouldRedirect: redirect})

                } else {
         
                    this.setState({shouldRedirect: '/dashboard'})
                    
                }
            
            }
        }

        document.body.classList.add("bg-default");

    }
    
    componentWillUnmount() {

        document.body.classList.remove("bg-default");

    }

    componentDidUpdate(e) {

        if (e.history.pathname !== e.location.pathname) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;

            if(this.refs.mainContent) {
                this.refs.mainContent.scrollTop = 0;
            }            
        }

    }
    getRoutes = routes => {
        return routes.map((prop, key) => {
      
            return (
                <Route
                    path={`/${base_layout}${prop.path}`}
                    component={prop.component}
                    key={key}
                />
            );

        });
    };


    getRedirectUrl = () => {
        var getUrl = window.location;
        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];

        return baseUrl
    }

    render() {

        if(this.state.shouldRedirect) {
            return <Redirect to={this.state.shouldRedirect} />
        }

        return (

        <>
            <div className="main-content" ref="mainContent">

                <Navbar className="navbar-horizontal navbar-main navbar bg-secondary " expand="lg" id="navbar-main">
                    <Container >
                        <div className="text-center">
                            <NavbarBrand to="/" tag={Link}>
                                <img alt="..."  src={keys.LOGO} />
                            </NavbarBrand>
                        </div>
                      
                    </Container>

                </Navbar> 
                
                <Switch>
                    {this.getRoutes(routes)}
                    <Redirect from="*" to="/login" />
                </Switch>
                
            </div>
        </>
        );
    }
}

const mapStateToProps = state => {
    return {
  
      viewing_user: state.auth.viewing_user,
  
    };
};
  
export default connect(mapStateToProps, '')(Auth);

