import DashHeader from 'components/markup/headers/DashHeader';
import { architeckCall } from 'database';
import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { getUrlParameter } from 'utils/urls';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";

import moment from 'moment'
import SinglePage from './ComponentsView/SinglePage'


const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const pagination = paginationFactory({
    sizePerPage: 10,
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {<select
                    name="datatable-basic_length"
                    aria-controls="datatable-basic"
                    className="form-control form-control-sm"
                    onChange={e => onSizePerPageChange(e.target.value)}
                >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>}{" "}
                entries.
            </label>
        </div>
    )
});
 
class ProjectsView extends React.Component {

    state = {
        page_array: [],
        data: [],
        page: '/',
        showPage: false,
        showSelect: true,
    }

    onShowPage = (page) => this.setState({page, showPage: true, showSelect: false})
    onShowSelect = () => this.setState({showPage: false, showSelect: true})

    columns = [
        {
            dataField: "page",
            text: "Page",
            sort: true,
            formatter: (cell, row) => {
                return <span style={{cursor: 'pointer'}} onClick={() => this.onShowPage(row.page)}>{row.page === '/' ? <b>HOME PAGE</b> : row.page}</span>
            }
           
        },{
            dataField: "views_unique",
            text: "Todays Unique ViewS",
            sort: true
        }, {
            dataField: "views_all",
            text: "Todays Total Views",
            sort: true
        }, 
    ]
  

    componentWillMount = async () => {

        const views = await architeckCall({
            method: 'get',
            url: `/api/v1/archk_analytics_views/find/${this.props.match.params._id}`
        })

        console.log(views)

        let page_array = []

        views.data.forEach(v => {

            let found_page = page_array.find(a => a.page === v.page);

            if(found_page) {
                found_page.views_all = found_page.views_all + v.views_all
                found_page.views_unique = found_page.views_unique + v.views_unique

            } else {
                page_array.push(v)
            }

        })

        this.setState({data: views.data, page_array})



    }

    
    
    render() {

        const data = [...this.state.data];
        const page = this.state.page;

        return (
            <>

            <Helmet>
                <title>Views</title>
                <meta name="description" content="Views" />
            </Helmet>

            <DashHeader 
                icon="fas fa-home" 
                icon_link="/dashboard" 
                title={<span>Views</span>} breadcrumb_1="Analytics"
                actionComponent={this.state.showPage ? (
                    <button onClick={this.onShowSelect} className="btn btn-sm btn-success">Back</button>
                ) : null}
            />

            <Container fluid>

               

                {this.state.ModalCreate}


               

                    {data && data.length ? (
                        <>

                        {this.state.showSelect && (
                            <Card>

                            <CardHeader>
                                <h3 className="mb-0">My Pages</h3>
                            </CardHeader>
            
                            <div className="table-vertical-align">
                                <ToolkitProvider
                                    data={this.state.page_array}
                                    keyField="_id"
                                    columns={this.columns}
                                    search
                                    exportCSV
                                >
                                {props => (
                                    <div className="py-4 table-responsive table-vertical-align">
                                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1 w-100" >
                                            <Row>
            
                                                <Col className="align-self-center">
                                                    <p className="text-sm" style={{whiteSpace: 'pre-wrap'}}>Recent days that do not show up mean no user has visited this page since the last entry.</p>
                                                </Col>
            
                                                <Col className="col-auto align-self-center">
                                                    <ExportCSVButton { ...props.csvProps } className="btn-sm btn-success mb-3" >
                                                        <span>Export CSV</span>
                                                    </ExportCSVButton>
                                                </Col>
            
                                            </Row>
                                        </div>
                                        <BootstrapTable 
                                            {...props.baseProps} 
                                            className="xxx" 
                                            bootstrap4={true} 
                                            pagination={pagination} 
                                            bordered={false}
                                        />
                                    </div>
                                )}
                                </ToolkitProvider>
                            </div>
            
                        </Card>
            
                        )}

                        {this.state.showPage && (
                            <SinglePage 
                                data={data.filter(d => d.page === page)} 
                                page="/"
                            />
                        )}

                        </>
                    ) : null}

                  
                 

                </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        app_sites: state.analytics.app_sites,

    };
};

export default connect(mapStateToProps, '')(ProjectsView);

