


import io from 'socket.io-client';
import Axios from 'axios';
import keys from '../keys'


let socket = io(keys.API_URL);


const returnListener = async (url, onSuccess, onError, method) => {

    //used for custom method types
    //defaults to get as this is a listener
    if(!method) {
        method = 'get'
    }

    try {
        const result = await Axios({
            method,
            url: keys.API_URL + url,
            
            headers: {
                withCredentials: "true",
                access_token: keys.ARCHITECK_MASTER_KEY
            },
          
        })

        onSuccess(result.data)

    } catch(e) {
    
        if(onError) {
            onError(e)
        }
    
    }

}

export class architeckLive {

    constructor() {

        //this is used to fire off a request when this class is loaded
        this.fired = [];
        //should we return any values on socket change
        this.shouldListen = true
    }

 

   
    async listen  (url, onSuccess, onError, method)  {

        //if this is our first listen, fire the get request
        if(!this.fired.includes(url)) {


            //run the request
            returnListener(url, onSuccess, onError, method)
            this.fired.push(url);

        }

        socket.on(url, async () => {


            if(this.shouldListen) {

                returnListener(url, onSuccess, onError, method)

            }
            
        });

    }

    destroy = () => {

        //don't return any values on for this class any more
        this.shouldListen = false

    }

}


export const architeckAction = async (settings) => {
    return new Promise(async(resolve) => {

        const method = settings.method;
        const url = settings.url;
        let data = settings.data;
        const headers = settings.headers;
        const architeck_live_events = settings.architeck_live_events;

        // if(!architeck_live_events) {
        //     console.log('no live events set a request made to url:' + method + ' ' + url + '. No socket will be fired.')
        // }

        if(!data) {
            data = {}
        }

        data.architeck_live_events = architeck_live_events;

        try {

            const result = await Axios({
                method,
                url: keys.API_URL + url,
                headers: {
                    ...headers,
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                data,
            })

            resolve(result.data)
        
        } catch(e) {
        
            resolve({success: false, message: 'architeckAction failed at url: ' + url + '. Error: ' + e.toString()})
        
        }

    })
}

/*
Documentation

example:

const call = await architeckCall({
    url: '/api/v1/crud/users/create
    method: 'POST',
    data: {
        email: 'test@gmail.com
    }
})

*/
 

export const architeckCall = async (settings) => {
    return new Promise(async(resolve) => {



        const method = settings.method;
        const url = settings.url;
        let data = settings.data;
        const headers = settings.headers;
       
        if(!data) {
            data = {}
        }

        try {
            const result = await Axios({
                method,
                url: keys.API_URL + url,
                headers: {
                    ...headers,
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
                data,
            })

            resolve(result.data)
        
        } catch(e) {
        
            resolve({success: false, message: 'architeckCall failed at url: ' + url + '. Error: ' + e.toString()})
        
        }

    })
}

export const architeckListen = new architeckLive()
