/*
Documentation

This is the navbar for the dashboard layout

*/

import classnames from "classnames";
import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, Navbar, NavItem, Row, UncontrolledDropdown } from "reactstrap";
import AvatarImage from 'components/functional/images/AvatarImage';


class LayoutNavbar extends React.Component {

	render() {

		const viewing_user = this.props.viewing_user

    	return (


			<Navbar className="navbar-top navbar-expand border-bottom navbar"  >
				<Container fluid>
					<Collapse navbar isOpen={true}>

						<Nav className="align-items-center ml-md-auto" navbar>

							<NavItem className="d-xl-none">
								<div
									onClick={this.props.toggleSidenav}
									className={classnames(
										"pr-3 sidenav-toggler",
										{ active: this.props.sidenavOpen },
										{ "sidenav-toggler-dark": this.props.theme === "dark" }
									)}	
								>
									<div className="sidenav-toggler-inner">
										<i className="sidenav-toggler-line" />
										<i className="sidenav-toggler-line" />
										<i className="sidenav-toggler-line" />
									</div>
								</div>
							</NavItem>

							<UncontrolledDropdown nav>

								<DropdownToggle className="nav-link" color="" tag="a">
									<i className="ni ni-ungroup" />
								</DropdownToggle>
							
								<DropdownMenu className="dropdown-menu-lg dropdown-menu-dark bg-default" right >
									<Row className="shortcuts px-4">

										<Col className="shortcut-item" xs="4" 	 >
											<Link to="/dashboard/settings">
												<span className="shortcut-media avatar rounded-circle bg-gradient-success">
												<i className="fas fa-cogs"></i>
												</span>
												<small>Settings</small>
											</Link>
										</Col>

										<Col className="shortcut-item" xs="4" 	 >
											<Link to="/dashboard/">
												<span className="shortcut-media avatar rounded-circle bg-gradient-info">
												<i className="fas fa-code-branch"></i>
												</span>
												<small>Projects</small>
											</Link>
										</Col>

									</Row>
								</DropdownMenu>

							</UncontrolledDropdown>

						</Nav>

						<Nav className="align-items-center ml-auto ml-md-0" navbar>

							<UncontrolledDropdown nav>

							<DropdownToggle className="nav-link pr-0" color="" tag="a">
								<Media className="align-items-center">
									<span className="avatar avatar-sm rounded-circle">
										<AvatarImage alt="profile" src={viewing_user.picture_url} />
									</span>

									<Media className="ml-2 d-none d-lg-block">
										<span className="mb-0 text-sm font-weight-bold">
											{viewing_user.given_name} {viewing_user.family_name}
										</span>
									</Media>

								</Media>
							</DropdownToggle>
							
							<DropdownMenu right>

								<DropdownItem className="noti-title" header tag="div">
									<h6 className="text-overflow m-0">Welcome!</h6>
								</DropdownItem>

								<Link to="/dashboard/settings">
									<DropdownItem	>
										<i className="ni ni-single-02" />
										<span>My profile</span>
									</DropdownItem>
								</Link>

								{this.props.viewing_user.is_admin ? (
									<>
										<a href="https://developers.architeck.io/developers">
											<DropdownItem	>
												<i className="fas fa-code"></i>
												<span>Developers</span>
											</DropdownItem>
										</a>

										<a href="https://projects.architeck.io/dashboard">
											<DropdownItem	>
												<i className="fas fa-home"></i>
												<span>User Dashboard</span>
											</DropdownItem>
										</a>
									</>

								) : null}
							
								<DropdownItem divider />

								<Link to="/auth/logout" >
									<DropdownItem>
										<i className="ni ni-user-run" />
										<span>Logout</span>
									</DropdownItem>
								</Link>

							</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
					</Collapse>
				</Container>
			</Navbar>

    	);
  	}
}


const mapStateToProps = state => {
  	return {

    	viewing_user: state.auth.viewing_user,

  	};
};

export default connect(mapStateToProps, '')(LayoutNavbar);