import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import system from './reducers/system';
import analytics from './reducers/analytics';
import auth from './reducers/auth';

//tell redux to match the following properties to each reducer
const rootReducer = combineReducers({
    
    analytics,
    system,
    auth
  
})
  
const store = createStore(rootReducer, applyMiddleware(thunk));;
export default store