

import * as actionTypes from '../actions';
import store from 'store';
import { architeckCall } from 'database';

export const setAppSites = async(company) => {
    return new Promise( async (resolve, reject) => {


        const sites = await architeckCall({
            method: 'get',
            url: '/api/v1/crud/archk_analytics_sites/list?loop=true&limit=500&find_by=company__' + company._id
        })


        if(sites.success) {

            dispatchActions(sites.data)

            resolve({success: true});
            return;

        } else {

            //error reporting here
            resolve({success: false, message: 'Failed to load app projects. '});
            return;

        }

    })

}

const dispatchActions = (data) => {

    store.dispatch({
        type: actionTypes.SET_APP_SITES,
        payload: {
            objects: data
        }
    });

    return true;
}