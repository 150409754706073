/*
Documentation

this is the wrapping component for the dashboard routes
it should check to see if a user is logged in 
if not kick them out the /auth/login

if a user is a developer but not an admin it should
redirect them to the developer screen

it will also load any data needed for the dashboard to work
before rendering its routes

*/

import React from "react";
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from "react-router-dom";
import { WaveSpinner } from "react-spinners-kit";
import routes from "./routes";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";

import { setAppSites } from 'store/functions/analytics';
//set the name of the routes to check for | in this case '/admin'
const base_route = 'dashboard';

class Dashboard extends React.Component {
    state = {
        sidenavOpen: true,
        logged_in: true,
        is_loading: false,
        show_loader: true,
        faderOpacity: 1,
    };

    //fade our loader out gracefully without settings 
    //state to many times and updating components over and over
    fadeLoader = () => {

        const fader = document.getElementById('AosFader')
        let opacity = 1;

        //set an interval to fade the opacity
        var refreshIntervalId = setInterval(() => {

            //if we are done fading out remote the loader
            if(opacity < 0) {
                clearInterval(refreshIntervalId);
                this.setState({show_loader: false})
            }

            opacity = opacity - .3

            //if page is changed dont through and error if fader is gone
            try {
                fader.style.opacity = opacity
            } catch (e) {

            }
            
        }, 100);
   
    }

    //get routes for this layout
    getRoutes = routes => routes.map((prop, key) => {

        //recursive to get the dropdown routes
        if (prop.collapse) {
            return this.getRoutes(prop.views);
        }

        return (
            <Route 
                exact path={`/${base_route}${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })
   
    // toggles collapse between mini sidenav and normal
    toggleSidenav = e => {
        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.add("g-sidenav-hidden");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
        }
        this.setState({ sidenavOpen: !this.state.sidenavOpen })
    };
   
    componentDidMount = async () => {

        //if the user is not logged in redirect them
        if(!this.props.viewing_user) {
            this.setState({shouldRedirect: '/auth/login'});
            return;
        }

        if(this.props.viewing_user.is_dev && !this.props.viewing_user.is_admin) {
            this.setState({shouldRedirect: '/developers'});
            return;
        }

         //add in any loading functions and promises here

         Promise.all([
            setAppSites(this.props.viewing_user.company),

        ]).then(values => {
            
            //we are done loading
            this.setState({is_loading: false})
            this.fadeLoader();
            
        })

    }

    render() {
        
    
        if(this.state.shouldRedirect) {
            return <Redirect to={this.state.shouldRedirect} />
        }

        if(this.state.show_loader) {
            return (
                <div 
                    id="AosFader"
                    className="top-0 bottom-0 right-0 left-0 position-fixed d-flex bg-white"
                    style={{ opacity: this.state.faderOpacity, zIndex: 9999999999999 }}
                >
                    <div className="align-self-center ml-auto mr-auto">
                        <WaveSpinner size={30} color="#fb6340" loading={true}/>
                    </div>
                </div> 
            )
        }

        return (
            <>
                <Sidebar
                    {...this.props} 
                    toggleSidenav={this.toggleSidenav}
                    base_route={base_route}
                />

                <div className="main-content" ref="mainContent" onClick={this.closeSidenav} >

                    <Navbar {...this.props} toggleSidenav={this.toggleSidenav} sidenavOpen={this.state.sidenavOpen} />

                    <Switch>
                        {this.getRoutes(routes)}
                        <Redirect from="*" to="/dashboard" />
                    </Switch>
                    
                    {/* <Footer /> */}

                </div>

                {this.state.sidenavOpen ? (
                    <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
                ) : null}

            </>

        );
    }
}

  
const mapStateToProps = state => {

	return {

	  viewing_user: state.auth.viewing_user,
  
	};
};
  
export default connect(mapStateToProps, '')(Dashboard);