/*
Documentation

This is the sidebar for the dashboard layout

*/

import classnames from "classnames";
import { PropTypes } from "prop-types";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, NavLink as NavLinkRRD } from "react-router-dom";
import { Collapse, Nav, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import routes from '../routes';
import keys from 'keys'

class Sidebar extends React.Component {

    state = {
        collapseOpen: false,
        initialState: {}
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => this.props.location.pathname ===  routeName ? "active" : ""

    // makes the sidenav normal on hover (actually when mouse enters on it)
    onMouseEnterSidenav = () => {
        if (!document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.add("g-sidenav-show");
        }
    };

    // makes the sidenav mini on hover (actually when mouse leaves from it)
    onMouseLeaveSidenav = () => {
        if (!document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-show");
        }
    };

    // toggles collapse between opened and closed (true/false)
    toggleCollapse = () => this.setState({  collapseOpen: !this.state.collapseOpen })

    // closes the collapse
    closeCollapse = () => this.setState({  collapseOpen: false }) 

    //sets the initial close or open of the sidebar
    getCollapseStates = routes => {
        let initialState = {};

        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                [prop.state]: this.getCollapseInitialState(prop.views),
                ...this.getCollapseStates(prop.views),
                ...initialState
                };
            }
            return null;
        });

        return initialState;

    };

    // this verifies if any of the collapses should be default opened on a rerender of this component
    getCollapseInitialState(routes) {

        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }

        return false;
    }

    // this is used on mobile devices, when a user navigates
    // the sidebar will autoclose
    closeSidenav = () => {
        if (window.innerWidth < 1200) {
            this.props.toggleSidenav();
        }
    };

    
    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = routes => {

        return routes.map((prop, key) => {


            if (prop.collapse) {

                var st = {};
                st[prop["state"]] = !this.state[prop.state];

                return (

                    <span key={key}>

                        {prop.divider ? (
                            <NavItem
                                className="mt-3"
                                style={{    paddingLeft: '1.5rem', paddingRight: '1.5rem'}}
                            >
                                <h6 className="navbar-heading p-0 text-muted">{prop.divider}</h6>
                                <hr className="my-2" />
                            </NavItem>
                        ): null}

                        <NavItem>

                            <NavLink
                                href="#"
                                data-toggle="collapse"
                                aria-expanded={this.state[prop.state]}
                                className={classnames({ active: this.getCollapseInitialState(prop.views) })}
                                onClick={e => {
                                    e.preventDefault();
                                    this.setState(st);
                                }}
                            >

                                {prop.icon ? <i className={prop.icon} /> : null}
                                <span className="nav-link-text">{prop.name}</span>
                                {prop.extraComponent && prop.extraComponent}

                            </NavLink>

                            <Collapse isOpen={this.state[prop.state]}>
                                <Nav className="nav-sm flex-column">
                                    {this.createLinks(prop.views)}
                                </Nav>
                            </Collapse>

                        </NavItem>

                    </span>
                );
            }

            return (
                prop.show_in_sidebar ? (
                    <span key={key}>
                    
                        {prop.divider ? (
                            <NavItem className="mt-3" style={{    paddingLeft: '1.5rem', paddingRight: '1.5rem'}} >
                                <h6 className="navbar-heading p-0 text-muted">{prop.divider}</h6>
                                <hr className="my-2" />
                            </NavItem>
                        ): null}

                        <NavItem className={this.activeRoute(`/${this.props.base_route}${prop.path}`)}>
                            <NavLink to={'/' + this.props.base_route + prop.path} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD} >
                                {prop.icon !== undefined ? (
                                    <>
                                        <i className={prop.icon} />
                                        <span className="nav-link-text">{prop.name}</span>
                                        {prop.extraComponent && prop.extraComponent}
                                    </>
                                ) : (
                                    prop.name
                                )}
                            </NavLink>
                        </NavItem>
                    
                    </span>
                ) : null
            );
        });
    };

    componentDidMount = () => this.setState({initialState: this.getCollapseStates(routes)}) 

    render() {

        const scrollBarInner = (

            <div className="scrollbar-inner">
                <div className="sidenav-header d-flex align-items-center">

                    <NavbarBrand to="/dashboard" tag={Link}>
                        <img alt={keys.COMPANY_NAME} className="navbar-brand-img" src={require("assets/img/brand/Logo.png")} />
                    </NavbarBrand>

                    <div className="ml-auto">
                        <div className={classnames("sidenav-toggler d-none d-xl-block", { })} onClick={this.props.toggleSidenav} >
                            <div className="sidenav-toggler-inner">
                                <i className="sidenav-toggler-line" />
                                <i className="sidenav-toggler-line" />
                                <i className="sidenav-toggler-line" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="navbar-inner">
                    <Collapse navbar isOpen={true}>                       
                        <Nav navbar>{this.createLinks(routes)}</Nav>
                    </Collapse>
                </div>
            </div>
        );

        return (
            <Navbar
                className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar"
                onMouseEnter={this.onMouseEnterSidenav}
                onMouseLeave={this.onMouseLeaveSidenav}
            >

                {navigator.platform.indexOf("Win") > -1 ? (
                    <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
                ) : (
                    scrollBarInner
                )}

            </Navbar>
        );
    }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false
};

Sidebar.propTypes = {
  toggleSidenav: PropTypes.func,
};

export default Sidebar;
