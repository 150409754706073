/*
Documentation

all dispatch actions should be declared in this file and used

this prevents mis-typing action names as it will through an error
by either the browser or IDE

*/




//app reducer
export const SET_LOADING                        = 'SET_LOADING';

export const SET_VIEWING_USER            = 'SET_VIEWING_USER';

export const TOGGLE_SYSTEM_ALERT             = 'TOGGLE_SYSTEM_ALERT';

export const SET_APP_SITES             = 'SET_APP_SITES';
export const SET_APP_VIEWS             = 'SET_APP_VIEWS';


