/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../actions';

const initialState = {
    app_views:     [],
    app_sites:     [],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_APP_VIEWS:

            return {
                ...state,
                app_views: action.payload.objects
            }

        case actionTypes.SET_APP_SITES:

            return {
                ...state,
                app_sites: action.payload.objects
            }

        default:
        

            return state;
    }
}



export default reducer;