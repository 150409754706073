import * as actionTypes from '../actions';
import store from '../index';
import {  architeckCall } from 'database';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


export const setViewingUser = async() => {
    return new Promise( async resolve => {

        //see if we know who the user is
        //we include a cookie with there id here to account for cors issues
        const viewing_user = await architeckCall({
            method: 'get',
            url: `/api/v1/auth/viewing_user?architeck_uid=${cookies.get('architeck_uid')}`
        })

        if(viewing_user.success) {

            if(viewing_user.user) {
                cookies.set('architeck_uid', viewing_user.user._id, { path: '/' });

            }

            store.dispatch({ type: actionTypes.SET_VIEWING_USER, payload: { objects: viewing_user.user } });

            resolve({success: true});
            return;

        } else {

            resolve({success: false});
            return;

        }

    })

}
