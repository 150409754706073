/*
Documentation

This page handles updating an admins profile

*/

import ArchiteckSingleMediaUpload from 'architeck-single-media-upload';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import States from 'components/markup/inputs/States';
import { architeckAction, architeckCall } from 'database';
import keys from 'keys';
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row } from "reactstrap";
import { setViewingUser } from 'store/functions/auth';

const required_form_fields = [
    'given_name',
    'family_name',
    'email',
    'phone',
    'address_line_1',

    'city',
    'state',
    'postal_code'
]

class Profile extends React.Component {

    state = {
        user: Object.assign({}, this.props.viewing_user),
        canSave: true
    }

    fireAlert = (error, text) => {

        this.setState({
            alert: (
                <ReactBSAlert
                    success={error ? false : true}
                    danger={!error ? false : true}
                    style={{ display: "block" }}
                    title={error ? 'Error' : 'Success'}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    {error ?  'Looks like something went wrong.' : text }
                </ReactBSAlert>
            )
        });
    };

   
    hideAlert = () => this.setState({ alert: null });

    onSave = async () => {

        this.setState({canSave: false})
        //copy state
        let newState = Object.assign({}, this.state.user);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
            } else {
                newState[field + "State"] = 'invalid'; 
                errors++
            }

        })

        //remove new_password fields
        delete newState.new_password
        delete newState.new_password_confirm
        //remove profile picture
        delete newState.picture_url

        this.setState({ user: newState });

        if(!errors) { 
           
            const updated = await architeckAction({
                method: 'patch',
                url: '/api/v1/users/update/' + this.props.viewing_user._id,
                architeck_live_events: ['/api/live/users/get/all'],          
                data: newState,
            });

            //if we have an error the alert will return an error message
            //we only need a success message
            this.fireAlert(!updated.success, 'Your profile has been updated successfully.')
            setViewingUser()

        }

        //after load make sure button work again
        this.setState({canSave: true})

    }

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state.user);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
          if (e.target.value) {
            newState.checkboxState = "valid";
          } else {
            newState.checkboxState = "invalid";
          }
        } else {
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }
        }

        this.setState({ user: newState });
    };

    onPasswordUpdate = async  () => {

        this.setState({error_password: false})

        const updated = await architeckCall({
            method: 'post',
            url: '/api/v1/auth/password_update',
            data: {
                password: this.state.user.new_password,
                password_confirm: this.state.user.new_password_confirm,
                _id: this.props.viewing_user._id,
            }
        });

        if(updated.success) {

            this.fireAlert(false, 'Your password has been successfully changed')
            this.setState({ user: {
                ...this.state.user,
                new_password: null,
                new_password_confirm: null
            }})

        } else {

            this.setState({error_password: updated.message})

        }

    }

    onMediaSelect = async (err, url) => {

        if(err) {
            this.fireAlert(true)
            console.log(err);
            return;
        }

        const updated = await architeckAction({
            method: 'patch',
            url: '/api/v1/users/update/' + this.props.viewing_user._id,
            architeck_live_events: ['/api/live/users/get/all'],          
            data: {
                picture_url: url
            },
        });

        this.fireAlert(!updated.success, "Your avatar has been updated successfully.");

        setViewingUser()

    }
    
  render() {

        return (
            <>

            <Container>     

                <Helmet>
                    <title>Settings</title>
                    <meta name="description" content="Settings" />
                </Helmet>

                {this.state.alert}
                <DashHeaderOpen 
                    icon="fas fa-home" 
                    icon_link="/dashboard" 
                    title={<span>Settings</span>} breadcrumb_1="Profile"  
                    actionComponent={
                        <Button
                            color="primary"
                            onClick={this.onSave}
                            size="sm"
                            disabled={this.state.canSave ? false : true}
                        >
                            Save Profile
                        </Button>
                    }
                />

                <Row>
                    <Col md={8}>
                        <Card>

                            <CardHeader>
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">My Account</h3>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <CardBody>

                                <h6 className="heading-small text-muted mb-4">User information</h6>

                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="given_name" > 
                                                First name 
                                            </label>
                                            <Input
                                                id="given_name"
                                                value={this.state.user.given_name || ''}
                                                placeholder="First name"
                                                type="text"
                                                valid={ this.state.user.given_nameState === "valid" }
                                                invalid={ this.state.user.given_nameState === "invalid" }
                                                onChange={e => this.onInputChange(e, "given_name") }
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="family_name" > 
                                                Last name 
                                            </label>
                                            <Input
                                                id="family_name"
                                                value={this.state.user.family_name || ''}
                                                placeholder="Last name"
                                                type="text"
                                                valid={ this.state.user.family_nameState === "valid" }
                                                invalid={ this.state.user.family_nameState === "invalid" }
                                                onChange={e => this.onInputChange(e, "family_name") }
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="email">
                                                Email address 
                                            </label>
                                            <Input
                                                id="email"
                                                value={this.state.user.email || ''}
                                                placeholder="email@example.com"
                                                type="email"
                                                valid={ this.state.user.emailState === "valid" }
                                                invalid={ this.state.user.emailState === "invalid" }
                                                onChange={e => this.onInputChange(e, "email") }  
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="phone"> 
                                                Phone 
                                            </label>
                                            <Input
                                                id="phone"
                                                value={this.state.user.phone || ''}
                                                placeholder="555-555-5555"
                                                type="tel"
                                                valid={ this.state.user.phoneState === "valid" }
                                                invalid={ this.state.user.phoneState === "invalid" }
                                                onChange={e => this.onInputChange(e, "phone") }
                                                
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <hr className="my-4" />
                                <h6 className="heading-small text-muted mb-4">Address</h6>

                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="address_line_1" > 
                                                Address Line 1 
                                            </label>
                                            <Input
                                                id="address_line_1"
                                                value={this.state.user.address_line_1 || ''}
                                                placeholder="198 Manhattan Ave"
                                                type="text"
                                                valid={ this.state.user.address_line_1State === "valid" }
                                                invalid={ this.state.user.address_line_1State === "invalid" }
                                                onChange={e => this.onInputChange(e, "address_line_1") }
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="address_line_2" > 
                                                Address Line 2 
                                            </label>
                                            <Input
                                                id="address_line_2"
                                                value={this.state.user.address_line_2 || ''}
                                                placeholder="Apt D"
                                                type="text"
                                                valid={ this.state.user.address_line_2State === "valid" }
                                                invalid={ this.state.user.address_line_2State === "invalid" }
                                                onChange={e => this.onInputChange(e, "address_line_2") }
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg="4">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="city" >
                                                City 
                                            </label>
                                            <Input
                                                id="city"
                                                value={this.state.user.city || ''}
                                                placeholder="Manhattan"
                                                type="text"
                                                valid={ this.state.user.cityState === "valid" }
                                                invalid={ this.state.user.cityState === "invalid" }
                                                onChange={e => this.onInputChange(e, "city") }
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="state" >
                                                State 
                                            </label>
                                            <Input
                                                id="state" 
                                                type="select"
                                                value={this.state.user.state || ''}
                                                valid={ this.state.user.stateState === "valid" }
                                                invalid={ this.state.user.stateState === "invalid" }
                                                onChange={e => this.onInputChange(e, "state") }
                                            >
                                                <option value="">Select City</option>
                                                <States />
                                            </Input>
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup> 
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="postal_code" > 
                                                Postal code 
                                            </label>
                                            <Input
                                                id="postal_code"
                                                value={this.state.user.postal_code || ''}
                                                placeholder="Apt D"
                                                type="text"
                                                valid={ this.state.user.postal_codeState === "valid" }
                                                invalid={ this.state.user.postal_codeState === "invalid" }
                                                onChange={e => this.onInputChange(e, "postal_code") }   
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Avatar</h3>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <CardBody>
                                <ArchiteckSingleMediaUpload 
                                    apiKey={keys.SYSTEM_API_KEY}
                                    postEndpoint={`${keys.API_URL}/api/cloudinary/upload`}
                                    onMediaSelect={(err, url) => this.onMediaSelect(err, url)} 
                                    crop={{ aspect: 1/1, width: 3000}}  
                                    maxWidth={50}
                                />
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
               
                <Card>

                    <CardHeader>
                        <Row className="align-items-center">
                            <Col xs="6">
                                <h3 className="mb-0">Security</h3>
                            </Col>
                            <Col className="text-right" xs="6">
                                <Button
                                    color="primary"
                                    onClick={this.onPasswordUpdate}
                                    size="sm"
                                    disabled={this.state.canSave ? false : true}
                                >
                                    Update Password
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody>

                        <h6 className="heading-small text-muted mb-4">Password</h6>

                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="new_password" > 
                                        New Password 
                                    </label>
                                    <Input
                                        id="new_password"
                                        value={this.state.user.new_password || ''}
                                        placeholder=""
                                        type="password"
                                        onChange={e => this.onInputChange(e, "new_password") }
                                    />
                                    {this.state.error_password ? <div className="text-danger">{this.state.error_password}</div> : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="new_password_confirm" > 
                                        Confirm New Password 
                                    </label>
                                    <Input
                                        id="new_password_confirm"
                                        value={this.state.user.new_password_confirm || ''}
                                        placeholder=""
                                        type="password"
                                        onChange={e => this.onInputChange(e, "new_password_confirm") }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>

            </Container>
            </>
        );
    }
}



const mapStateToProps = state => {
    return {
  
      viewing_user: state.auth.viewing_user,
  
    };
};
  
export default connect(mapStateToProps, '')(Profile);