/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../actions';

const initialState = {
    alerts: {
        show:     false,
        text:     null,
        type:     null,
    }
}

const reducer = (state = initialState, action) => {

  

    switch (action.type) {

        case actionTypes.TOGGLE_SYSTEM_ALERT:

            return {
                ...state,
                alerts: {
                    show: action.payload.boolean,
                    text: action.payload.text,
                    type: action.payload.type,
                }
            }

        default:
        

            return state;
    }
}



export default reducer;