import * as actionTypes from '../actions';

const initialState = {

    viewing_user: null,

}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        
        case actionTypes.SET_VIEWING_USER:

            //set a logged_in prop on the viewing user
            if(action.payload.objects && action.payload.objects._id) {
                
                action.payload.objects.logged_in = true;

            }

            let user = null

            if(action.payload.objects && action.payload.objects._id) {
                user = { ...action.payload.objects, }
            }

            return {
                ...state,
                viewing_user: user
            }

        default:
        
            return state;

    }

}

export default reducer;