/*
Documentation

this file renders the site layout and should redirect to the login page

*/

import React from "react";
import { Redirect } from "react-router-dom";

class Site extends React.Component {

	render() {

		return (

			<Redirect to="/auth/login" />

		);
	}
}

export default Site;
