/*
Documentation

this is the footer for the dashboard layout

*/

import React from "react";
import { Link } from 'react-router-dom';
import { Col, Container, Nav, NavItem, Row } from "reactstrap";
import keys from 'keys'

class Footer extends React.Component {
	render() {
		return (
            <Container fluid>
                <footer className="footer pt-0">
                    <Row className="align-items-center justify-content-lg-between">

                        <Col lg="6">
                            <div className="copyright text-center text-lg-left text-muted">
                                © {new Date().getFullYear()}{' '}
                                {keys.COMPANY}{' '}&mdash;{' '}
                                <a className="font-weight-bold ml-1" href="https://architeck.io" rel="noopener noreferrer" target="_blank">
                                    Software Built By Architeck
                                </a>
                            </div>
                        </Col>

                        <Col lg="6">

                            <Nav className="nav-footer justify-content-center justify-content-lg-end">

                                <NavItem>
                                    <Link className="nav-link" to="/dashboard/settings">My Profile</Link>
                                </NavItem>

                                <NavItem>
                                    <Link className="nav-link" href="logout" to="/auth/logout">Logout</Link>
                                </NavItem>

                            </Nav>
                            
                        </Col>

                    </Row>
                </footer>
            </Container>
		);
	}
}

export default Footer;
