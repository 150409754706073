import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { getProjectStepImg, getProjectStepName } from 'utils/projects';

class Profile extends React.Component {

    state = {
        projects: [],
        canSave: true
    }

    componentWillMount = () => {


        if(!this.props.viewing_user.company) {
            return;
        }

        this.setState({sites: this.props.app_sites})

    }
    
    render() {

        return (
            <>

            <Container>     

                <Helmet>
                    <title>Analytics</title>
                    <meta name="description" content="Projects" />
                </Helmet>

                {this.state.alert}

                <Card className="mt-4">

                    <CardHeader>
                        <h2 className="mb-0">Architeck Analytics</h2>
                    </CardHeader>

                    <CardBody>
                        <Row>
                            <Col lg={2}>
                                <img alt="..." className="w-100" src="https://architeck.io/static/media/svg7.af42bbfe.svg" />
                            </Col>
                            <Col lg={10}>
                                <h4 className="mt-2 mt-lg-0">Tracking Built For Easy Insights.</h4>
                                <p>Analytics are hard. There's too much data, not enough insights and its so easy to get lost in the sauce. That's why we created Architeck Analytics. Drop in your tracking code and watch your site grow with the information you need to make key decisions.</p>
                            </Col>
                        
                        </Row>

                    </CardBody>
                </Card>


                <Row className="mb-9" >

                    {this.state.sites.length ? this.state.sites.map(s => (
                        <Col key={s._id} xl={4}>
                            <Link to={"/dashboard/analytics/" + s._id}>
                                <Card className="mb-4">
                                    <CardHeader className="text-center">
                                        <h2 className="mb-0">{s.name}</h2>
                                        <h5 className="text-muted">
                                            View Analytics
                                        </h5>

                                    </CardHeader>
                                    <CardBody className="text-center">
                                        <h2>{s.hostname}</h2>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    )): (
                       <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h2>Your account does not have any sites being tracked assigned to it yet.</h2>
                                </CardBody>
                            </Card>
                       </Col>

                    )}
                </Row>

            </Container>
            </>
        );
    }
}



const mapStateToProps = state => {
    return {
  
      viewing_user: state.auth.viewing_user,
      app_sites: state.analytics.app_sites,
  
    };
};
  
export default connect(mapStateToProps, '')(Profile);